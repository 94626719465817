const indicator = {
    MockupSent: {
        icon: 'mdi-open-in-app',
        class: 'musent',
        section: 'mockupsent',
    },
    ArtReceived: {
        icon: 'mdi-inbox-arrow-down',
        class: 'artreceived',
        section: 'haveart',
    },
    ArtApproved: {
        icon: 'mdi-check-all',
        class: 'artapproved',
        section: 'artapproved',
    },
    nothere: { icon: '', class: '', section: 'noart' },
    Approved: { icon: 'mdi-thumb-up-outline', class: 'approved', section: 'project' },
    Canceled: { icon: 'mdi-block-helper', class: 'canceled', section: 'project' },
    ContractSigned: {
        icon: 'mdi-text-box-check-outline',
        class: 'signed',
        section: 'contract',
    },
    ContractWritten: { icon: 'mdi-text-box-outline', class: 'written', section: 'contract' },
    Declined: { icon: 'mdi-thumb-down-outline', class: 'declined', section: 'project' },
    'Declined-C19': {
        icon: 'mdi-thumb-down-outline',
        class: 'declined-c19',
        section: 'project',
    },
    Pending: { icon: 'mdi-progress-question', class: 'pending', section: 'project' },
    Draft: { icon: '', class: 'draft', section: 'project' },
    blank: { icon: 'mdi-progress-question', class: 'pending', section: 'project' },
    Active: { icon: '', class: '', section: 'noart' },
    Rush: { icon: '', class: 'rush', section: 'rush' },
}

export default {
    indicator
}