import { apiGetContactInfo, apiSaveContact, apiModDm, apiImportStores } from '@/api/contact.api'
import store from '@/store'

const initialState = () => ({
    title: 'Contact',
    depmiclist: [],
    depmicmissinglist: [],
    depmicmissingfields: [],
    depmicmissingstores: [],
    depmictotaldms: 0,
    dmstatus: {
        savemsg: false,
        savemsgerr: false,
        storereassign: false,
        missinginfo: false,
        displaymissing: false,
        uploadingfile: false,
        search: '',
        templates: [],
        missingselected: '',
        missingstoresel: '',
        noassigns: false,
        nostoreassigns: false,
    },
    depmic: {},
    dm: {
        noassigns: false,
        allnoassigns: false,
        selectedupdate: '',
        updatevals: [],
        selectupdate: false,
        readyforupdate: true,
        whatfield: '',
        saveinfo: false,
    },
    sgdblist: [],
    matchstores: [],
    assistanttypes: [],
})

const initialDmState = () => ({
    depmic: {
        area: '',
        artapprovalemail: '',
        artapprovalname: '',
        assistant: '',
        assistantemail: '',
        assistantlist: '',
        brand: '',
        buyer: '',
        buyeremail: '',
        company: '',
        dmm: '',
        dmmemail: '',
        house: '',
        selected: '',
        stores: [],
        totalselstores: 0,
        vendorcontact: '',
        vendoremail: '',
        vendorphone: '',
    },
})

const state = initialState()
//this.$store.commit('RESET_DEPMIC')
state.depmic = initialDmState()

/* ---
  we break out the different calls to apiGetContactInfo
  in case we have a single end point in the future
--- */
const actions = {
    reset({ commit }) {
        commit('RESET')
    },
    async fetchDmList({ commit }) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        let user = store.getters['Sgs/getUser']

        await apiGetContactInfo({ op: 'depmiclist', user: user })
            .then(response => {
                commit('SET_DMLIST', response.data)
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                console.log('FETCH DM LIST ERROR HERE')
                console.log(err)
            })
    },
    async fetchStoreGroupList({ commit }) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiGetContactInfo({ op: 'storegrouplist' })
            .then(response => {
                commit('SET_SGLIST', response.data)
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                console.log('FETCH SG LIST ERROR HERE')
                console.log(err)
            })
    },
    async fetchStoreListByName({ commit }, val) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiGetContactInfo({ op: 'storelistbyname', mic: val })
            .then(response => {
                commit('SET_MATCHSTORES', response.data)
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                console.log('FETCH STORE LIST BY NAME ERROR HERE')
                console.log(err)
            })
    },
    async fetchMissing({ commit }) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        let user = store.getters['Sgs/getUser']

        await apiGetContactInfo({ op: 'checkempty', user: user, quickcheck: false })
            .then(response => {
                commit('SET_DMMISSING', response.data)
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                console.log('FETCH DM MISSING ERROR HERE')
                console.log(err)
            })
    },
    async fetchSingle({ state, commit }) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        if (state.depmic.selected) {
            await apiGetContactInfo({ op: 'getsingle', dmid: state.depmic.selected })
                .then(response => {
                    commit('SET_DEPMIC', response.data)
                    commit('SET_ASSISTANTTYPES', response.data)
                    commit('Sgs/SET_REFRESHING', false, { root: true })
                })
                .catch(function(err) {
                    console.log('FETCH DM SINGLE ERROR HERE')
                    console.log(err)
                })
        } else {
            commit('RESET_DEPMIC')
        }
    },
    async fetchUpdateVals({ state, commit }, vals) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiGetContactInfo({ op: 'getupdatevals', dm: state.depmic.selected, field: vals.field })
            .then(response => {
                commit('SET_UPDATEVALS', response.data)
                commit('SET_UPDATEWHATFIELD', vals.field)

                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                console.log('FETCH UPDATE VALS ERROR HERE')
                console.log(err)
            })
    },
    async saveAssistant({ state, commit, dispatch }, val) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        let user = store.getters['Sgs/getUser']

        console.log('SAVE ASSISTANT')
        console.log(val)
        console.log(user)

        await apiSaveContact({
            op: 'saveit',
            whichone: val.whichone,
            depmic: state.depmic,
            user: user,
            contactinfo: val,
        })
            .then(ret => {
                console.log('SAVE ASSIST')
                console.log(ret)

                dispatch('Contact/fetchDmList', null, { root: true })

                commit('SET_SAVEMSG', true)

                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                commit('SET_SAVEMSG', false)

                console.log('SAVE DM ERROR HERE')
                console.log(err)
            })
    },
    async saveContact({ state, commit, dispatch }, val) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        let user = store.getters['Sgs/getUser']

        await apiSaveContact({
            op: 'saveit',
            whichone: val.whichone,
            depmic: state.depmic,
            user: user,
            contactinfo: val,
        })
            .then(() => {
                dispatch('Contact/fetchDmList', null, { root: true })

                commit('SET_SAVEMSG', true)

                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                commit('SET_SAVEMSG', false)

                console.log('SAVE DM ERROR HERE')
                console.log(err)
            })
    },
    async makeDm({ dispatch, commit }, vals) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        let getlater = vals.dep + '-' + vals.mic

        await apiModDm(vals)
            .then(() => {
                commit('SET_DEPMICSEL', getlater)

                dispatch('fetchDmList')
                dispatch('fetchSingle')

                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                console.log('MAKE DM ERROR HERE')
                console.log(err)
            })
    },
    async importStores({ commit, dispatch }, file) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiImportStores(file)
            .then(response => {
                commit('SET_UPLOADINGFILE', false)

                console.log(response)
                alert('File Processed: ' + response.data.time + ' seconds')
                dispatch('fetchSingle')

                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                commit('SET_UPLOADINGFILE', false)

                console.log('IMPORT ERROR HERE')
                console.log(err)
            })
    },
    async modStoreBulk({ state, dispatch, commit }, val) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        let user = store.getters['Sgs/getUser'],
            dep = state.depmic.selected.split('-')[0],
            mic = state.depmic.selected.split('-')[1]

        await apiSaveContact({ op: 'bulkchange', changeop: val, dep: dep, mic: mic, user: user })
            .then(() => {
                dispatch('fetchSingle')

                commit('SET_STOREREASSIGN', true)

                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                commit('SET_STOREREASSIGN', false)
                console.log('BULK ASSIGN ERROR HERE')
                console.log(err)
            })
    },
    async modStoreAssign({ dispatch, commit }, val) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        let user = store.getters['Sgs/getUser']

        await apiSaveContact({
            op: 'changestore',
            selval: val.aval,
            store: val.store,
            dep: val.dep,
            mic: val.mic,
            user: user,
        })
            .then(() => {
                dispatch('fetchSingle')

                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                console.log('SINGLE STORE ASSIGN ERROR HERE')
                console.log(err)
            })
    },
}

const mutations = {
    RESET(state) {
        const newState = initialState()

        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
    RESET_DEPMIC(state) {
        state.depmic = initialDmState()
    },
    SET_DMLIST(state, val) {
        state.depmiclist = val.recs
    },
    SET_SGLIST(state, val) {
        state.sgdblist = val.recs
    },
    SET_MATCHSTORES(state, val) {
        state.matchstores = val.recs
    },
    SET_SAVEMSG(state, val) {
        if (val) {
            state.dmstatus.savemsg = true
            state.dmstatus.savemsgerr = false
        } else {
            state.dmstatus.savemsg = false
            state.dmstatus.savemsgerr = true
        }
    },
    SET_DEPMICSEL(state, val) {
        state.depmic.selected = val
    },
    SET_DEPMIC(state, val) {
        let totis = 0,
            totnot = 0
        //alistwork = JSON.parse(state.depmic.assistantlist)

        state.depmic = val.recs.dm
        state.depmic.selected = val.recs.dmsel

        state.depmic.stores = val.recs.assign

        for (let idx = 0; idx < val.recs.assign.length; idx++) {
            if (val.recs.assign[idx].value === 'X') {
                state.depmic.stores[idx].isassign = 'is-assigned'
                state.depmic.stores[idx].aval = true
                totis++
            } else {
                state.depmic.stores[idx].isassign = 'not-assigned'
                state.depmic.stores[idx].aval = false
                totnot++
            }
        }

        if (totnot === val.recs.assign.length || totnot > val.recs.assign.length) {
            state.dmstatus.nostoreassigns = true
        }

        if (state.dmstatus.search !== '') {
            state.depmic.totalselstores = totis
        }

        state.depmic.assistantlist = JSON.parse(state.depmic.assistantlist)

        if (state.depmic.assistantlist.length === 1) {
            if (state.depmic.assistantlist[0].aid === null && state.depmic.assistantlist[0].tid === null) {
                state.depmic.assistantlist = []
            }
        }

        state.dmstatus.templates = val.recs.template
    },
    SET_DMMISSING(state, val) {
        state.depmicmissinglist = val.recs.displist
        state.depmicmissingfields = val.recs.fields
        state.depmicmissingstores = val.recs.stores
        state.depmictotaldms = val.recs.totaldms

        state.dmstatus.displaymissing = true

        if (val.missing) {
            state.dmstatus.missinginfo = true
        } else {
            state.dmstatus.missinginfo = false
        }

        if (val.assmissing) {
            state.dmstatus.noassigns = true
        } else {
            state.dmstatus.noassigns = false
        }
    },
    SET_UPLOADINGFILE(state, val) {
        state.dmstatus.uploadingfile = val
    },
    SET_STOREREASSIGN(state, val) {
        state.dmstatus.storereassign = val
    },
    SET_UPDATEVALS(state, val) {
        state.dm.updatevals = val.recs
        state.dm.selectupdate = true
    },
    SET_UPDATEWHATFIELD(state, val) {
        state.dm.whatfield = val
    },
    SET_UPDATESAVEINFO(state, val) {
        state.dm.saveinfo = val
    },
    SET_ASSISTANTTYPES(state, val) {
        for (let idx = 0; idx < val.recs.atypes.length; idx++) {
            state.assistanttypes.push({ text: val.recs.atypes[idx].text, value: val.recs.atypes[idx].value * 1 })
        }
        //state.assistanttypes = val.recs.atypes
    },
}

const getters = {
    getDmList(state) {
        return state.depmiclist
    },
    getSgList(state) {
        return state.sgdblist
    },
    getMatchStores(state) {
        return state.matchstores
    },
    getDepMic(state) {
        return state.depmic
    },
    getDmTotalAss(state) {
        return state.depmictotaldms
    },
    getDmTotalMissingList(state) {
        return state.depmicmissinglist
    },
    getDmTotalMissingFields(state) {
        return state.depmicmissingfields
    },
    getDmTotalMissingStores(state) {
        return state.depmicmissingstores.length
    },
    getDmMissingStores(state) {
        return state.depmicmissingstores
    },
    getDmStatus(state) {
        return state.dmstatus
    },
    getDm(state) {
        return state.dm
    },
    getUpdateVals(state) {
        return state.updatevals
    },
    getAssistantTypes(state) {
        return state.assistanttypes
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
