<template>
    <v-app>
        <v-main>
            <router-view :key="$route.fullPath"></router-view>
        </v-main>
        <Navigation />
        <v-footer app>
            <span>&copy; {{ scyear }} Stevenson Color </span>
            <v-flex class="pl-4" style="opacity: 0.5; font-size: 0.75rem"> version: {{ sgsver }} </v-flex>
        </v-footer>
    </v-app>
</template>

<script>
import Navigation from '@/components/Navigation'
import moment from 'moment'

export default {
    components: {
        Navigation,
    },
    data: () => ({
        scyear: moment().format('YYYY'),
        sgsver: process.env.VUE_APP_VERSION,
    }),
    watch: {
        $route(to) {
            document.title = to.meta.title + " - Dillard's JSF" || 'SGS'
        },
    },
}
</script>
