import httpClient from './httpClient'

const apiGetContactInfo = val => httpClient.post('/inc/contactsv2.php', val),
    apiSaveContact = val => httpClient.post('/inc/contactsv2.php', val),
    apiModDm = val => httpClient.post('/inc/utilv2.php', val),
    apiImportStores = file =>
        httpClient.post('/inc/process-spreadsheet-storeassignv2.php', file, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

export { apiGetContactInfo, apiSaveContact, apiModDm, apiImportStores }
