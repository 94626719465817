import { apiAdminPrice, apiGetPriceTiers, apiSaveTier, apiRemoveTier } from "@/api/utility.api"
import store from '@/store'

const initialState = () => ({
    title: 'System Utilities',
    partsprice: [],
    ppactive: [],
    ppinactive: [],
    currenttiers: {}
})

const state = initialState()

const actions = {
    reset({ commit }) {
        commit('RESET')
    },
    async fetchPartsPrice({ commit }) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiAdminPrice({ op: 'getparts' })
            .then(response => {
                //console.log('PRICE RET')
                //console.log(response.data.recs)

                commit('SET_PARTSPRICE', response.data.recs)
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function (err) {
                console.log('FETCHPARTSPRICE ERROR HERE')
                console.log(err)
            })
    },
    async fetchTiers({ commit }, val) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiGetPriceTiers({ op: 'pricetiers', matrixid: val.matrixid })
            .then(response => {
                commit('SET_TIERS', response.data.recs)
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function (err) {
                console.log('FETCH PRICE TIER ERROR HERE')
                console.log(err)
            })
    },
    async savePrice({ commit }, val) {
        let user = store.getters['Sgs/getUser']

        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiSaveTier({ op: 'savetierprice', tierid: val.tierid, price: val.price, effectivedate: val.effectivedate, user: user })
            .then(() => {
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function (err) {
                console.log('SAVE TIER PRICE ERROR HERE')
                console.log(err)
            })
    },
    async nixTier({ commit }, val) {
        let user = store.getters['Sgs/getUser']

        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiRemoveTier({ op: 'removetier', tierid: val.tierid, user: user })
            .then(() => {
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function (err) {
                console.log('REMOVE TIER PRICE ERROR HERE')
                console.log(err)
            })
    },
    async modActive({ commit }, val) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiSaveTier({ op: 'modactive', tierid: val.tierid, partid: val.partid, active: val.active })
            .then(() => {
                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function (err) {
                console.log('MOD ACTIVE ERROR HERE')
                console.log(err)
            })
    },
}

const mutations = {
    RESET(state) {
        const newState = initialState()
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
    SET_PARTSPRICE(state, val) {
        state.partsprice = val
        state.ppactive = []
        state.ppinactive = []

        for (let idx = 0; idx < val.length; idx++) {
            if (val[idx].active == 1) {
                state.ppactive.push(val[idx])
            } else {
                state.ppinactive.push(val[idx])
            }
        }
    },
    SET_TIERS(state, val) {
        let dispedate = '',
            candelete = false

        state.currenttiers = {}

        state.currenttiers['matrixid'] = val[0].matrixid
        state.currenttiers['product'] = val[0].product
        state.currenttiers['ranges'] = []

        for (let idx = 0; idx < val.length; idx++) {
            /* --- 631177200.00000 = 1990-01-01 mysql v8--- */
            /* --- 631170000.00000 = 1990-01-01 mysql v5.7--- */
            if (val[idx].effective_date === '631177200.000000' ||
                val[idx].effective_date === '631170000.000000'
            ) {
                dispedate = ''
                candelete = false
            } else {
                /* --- convert that GD string to number and add three hours to accomodate mysql!! --- */
                dispedate = new Date(((val[idx].effective_date * 1) + 10800) * 1000).toLocaleDateString()
                candelete = true
            }

            state.currenttiers.ranges.push({
                id: val[idx].id,
                name: val[idx].name,
                lower: val[idx].lower,
                upper: val[idx].upper,
                maxupper: val[idx].maxupper,
                price: val[idx].price,
                effective_date: dispedate,
                candelete: candelete
            })
        }
    }
}

const getters = {
    getPartsPrice(state) {
        return state.partsprice
    },
    getPpActive(state) {
        return state.ppactive
    },
    getPpInactive(state) {
        return state.ppinactive
    },
    getCurrentTiers(state) {
        return state.currenttiers
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}