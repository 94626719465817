import status from '@/vars/status'
import store from '@/store'

import {
    apiGetJsfList,
    apiSetJsfLock,
    apiGetProgsAndStores,
    apiMakeModGetJsf,
    apiGetLiForProg,
    apiSetVisualDates,
    apiGetZeroPrice,
} from '@/api/jsf.api'

const initialState = () => ({
    title: 'JSF',
    search: '',
    jsf: {
        id: 0,
        jsfid: '[NEW]',
        department: '',
        mic: '',
        requestedfor: 'SGS',
        requesttype: 'New',
        program: '',
        fixedkit: false,
        created: new Date().toLocaleDateString(),
        lastupdate: new Date().toLocaleDateString(),
        vdatestart: new Date().toISOString().slice(0, 10),
        selectedstores: [],
        title: '',
        visualdate: '',
        instoredate: '',
        artdue: '',
        artapproval: '',
        shipdate: '',
        requestedby: '',
        area: '',
        house: '',
        brand: '',
        buyer: '',
        vendorcontact: '',
        vendoremail: '',
        vendorphone: '',
        artapprovalname: '',
        artapprovalemail: '',
        notes: '',
        lineitems: [],
        partconfig: {},
        doorsconfirmed: false,
        rush: false,
        status: 'New',
        havepande: false,
        havepandepm4only: false,
        pandecount: 0,
        pandecountpm4only: 0,
        pandestores: [],
    },
    jsfrequestfor: [],
    jsfrequestforsel: 'All',
    jsflist: [],
    jsfmaster: [],
    showmuinfo: false,
    muinfo: '',
    programlist: [],
    basestorelist: [],
    storelist: [],
    pandeitems: [
        { name: '22x28 POSTERS', upd: 2 },
        { name: '8.5" x 11" SIGNS', upd: 2 },
        { name: 'Tara Vitrine 7" x 4" Signs', upd: 1 },
    ],
    tabjsfids: {
        all: [],
        draft: [],
        pending: [],
        approved: [],
        declined: [],
        written: [],
        signed: [],
        rush: [],
        onhold: [],
        needsprice: [],
    },
    jsfzeropricelist: [],
    jsflistmaster: [],
})

const state = initialState()

const actions = {
    reset({ commit }) {
        commit('RESET')
    },
    async fetchList({ commit }) {
        let user = store.getters['Sgs/getUser']
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiGetZeroPrice()
            .then(response => {
                //console.log('ZERO RET')
                //console.log(response)

                commit('SET_ZEROPRICELIST', response.data.recs)

                apiGetJsfList({ op: 'jsflist', showall: store.getters['Sgs/getAdminView'], email: user.email })
                    .then(response => {
                        //console.log('GOT JSF LIST')
                        //console.log(response)

                        commit('SET_JSFLIST', response.data.recs)
                        commit('Sgs/SET_REFRESHING', false, { root: true })
                        commit('SET_JSFREQUESTFOR', response.data.additional)
                        commit('SET_JSFLISTSTATES')
                        commit('SET_JSFLISTMASTER')
                    })
                    .catch(function(err) {
                        console.log('FETCHLIST ERROR HERE')
                        console.log(err)
                    })
            })
            .catch(function(err) {
                console.log('FETCH ZERO PRICE ERROR HERE')
                console.log(err)
            })
    },
    async fetchProgsAndStores({ state, commit }) {
        commit('Sgs/SET_REFRESHING', true, { root: true })

        await apiGetProgsAndStores({ op: 'dminfo', dep: state.jsf.department, mic: state.jsf.mic })
            .then(response => {
                if (!response.data.recs.progs.length || !response.data.recs.contactinfo || !response.data.recs.stores) {
                    commit(
                        'Sgs/SET_MSG',
                        [
                            true,
                            'The system was unable to identify a Beauty Area or stores associated with this D/M, please check the contact record for accurate information.',
                            'red',
                            '-1',
                            't',
                        ],
                        { root: true }
                    )
                } else {
                    commit('SET_PROGRAMLIST', response.data.recs.progs)
                    commit('SET_CONTACTINFO', response.data.recs.contactinfo)
                    commit('SET_STORELIST', response.data.recs.stores)
                    commit('SET_SELECTEDSTORES', response.data.recs.stores)
                }

                commit('Sgs/SET_REFRESHING', false, { root: true })
            })
            .catch(function(err) {
                console.log('FETCHPROGRAMS ERROR HERE')
                console.log(err)
            })
    },
    async setLock({ dispatch }, vals) {
        await apiSetJsfLock(vals)
            .then(response => {
                if (response) {
                    /* --- noop --- */
                }

                dispatch('Jsf/fetchList', null, { root: true })
            })
            .catch(function(err) {
                console.log('SETLOCK ERROR HERE')
                console.log(err)
            })
    },
    async saveJsfLineItems({ state, commit }) {
        const user = store.getters['Sgs/getUser']
        let vals = state.jsf

        //console.log('SAVING LINE ITEMS')
        //console.log(state.jsf.lineitems)

        vals.user = user
        vals.op = 'saveli'

        let liret = await apiMakeModGetJsf(vals)
            .then(response => {
                //console.log('LI COMPLETE')
                //console.log(response.data)

                if (response.data.status === 'success') {
                    commit('Sgs/SET_MSG', [true, 'JSF Successfully Saved', 'green', '-1', 't'], { root: true })
                } else {
                    commit(
                        'Sgs/SET_MSG',
                        [
                            true,
                            'The system could not save this JSF.  Please try again or contact an administrator.',
                            'red',
                            '-1',
                            't',
                        ],
                        { root: true }
                    )
                }

                return response
            })
            .catch(function(err) {
                console.log('SAVELI ERROR HERE')
                console.log(err)
            })

        return liret
    },
    async saveJsf({ state, commit, dispatch }, status) {
        const user = store.getters['Sgs/getUser']
        let vals = state.jsf

        vals.user = user
        vals.op = 'save'
        vals.submitstatus = status
        //vals.selectedstorescount = state.jsf.selectedstores.length

        await apiMakeModGetJsf(vals)
            .then(response => {
                if (response) {
                    console.log('SAVE COMPLETE')
                    //console.log(response.data)
                    console.log(response)

                    commit('SET_JSFID', response.data.recs.jsfid)
                    commit('SET_ID', response.data.recs.id)

                    dispatch('Jsf/saveJsfLineItems', null, { root: true })
                        .then(dresponse => {
                            //console.log('LI COMPLETE - BACK FROM DISPATCH')
                            //console.log(dresponse.data)
                            if (dresponse) {
                                /* --- no op --- */
                            }

                            window.location = '/edit/jsf/' + state.jsf.id
                        })
                        .catch(function(err) {
                            console.log('SAVEJSF LI ERROR HERE')
                            console.log(err)
                        })
                }
            })
            .catch(function(err) {
                console.log('SAVEJSF PROPER ERROR HERE')
                console.log(err)
            })
    },
    async fetchLis({ state, commit }) {
        await apiGetLiForProg({ program: state.jsf.program })
            .then(response => {
                if (response) {
                    console.log('GETTING LI')
                    console.log(response)

                    commit('SET_PARTCONFIG', response.data)
                    commit('SET_FIXEDKIT', response.data.fixedkit)
                }
            })
            .catch(function(err) {
                console.log('FETCHLIS ERROR HERE')
                console.log(err)
            })
    },
    async modJsfMeta({ state }, val) {
        const user = store.getters['Sgs/getUser']
        //console.log('STATE PLACEHOLDER')
        console.log(state.title)

        await apiMakeModGetJsf({
            op: 'modmeta',
            jsfnumber: val.jsfnumber,
            metakey: val.metakey,
            metavalue: val.metavalue,
            user: user,
        })
    },
    async modJsfStatus({ dispatch }, val) {
        const user = store.getters['Sgs/getUser']

        await apiMakeModGetJsf({
            op: 'modstatus',
            id: val.jsfid,
            jsfnumber: val.jsfnumber,
            status: val.status,
            user: user,
        })
            .then(response => {
                //console.log('AFTER STATUS 00')
                //console.log(response)
                if (response.data.recs.status === 'success') {
                    dispatch('Jsf/fetchList', null, { root: true })
                        .then(() => {
                            //console.log('AFTER STATUS')
                            //console.log(state.jsflist)
                            //state.jsflist.find(o => o.jsfid === val[0]).currentaction = ''
                        })
                        .catch(function(err) {
                            console.log('MOD JSF STATUS AFTER FETCH ERROR HERE')
                            console.log(err)
                        })
                }
            })
            .catch(function(err) {
                console.log('MOD JSF STATUS ERROR HERE')
                console.log(err)
            })
    },
    async fetchJsf({ state, commit }, val) {
        await apiMakeModGetJsf({ op: 'getjsf', id: val })
            .then(response => {
                if (response) {
                    //console.log('GET JSF')
                    //console.log(response)

                    commit('SET_JSF', response.data.recs)

                    commit('SET_STORELIST', response.data.recs.storelist)
                    commit('SET_PROGRAMLIST', response.data.recs.progs)

                    //console.log('LINE ITEMS')
                    //console.log(response.data.recs.jsf[0].lineitems)

                    for (let idx = 0; idx < state.jsf.lineitems.length; idx++) {
                        state.jsf.lineitems[idx].total =
                            state.jsf.lineitems[idx].perdoor * state.jsf.selectedstores.length
                    }
                }
            })
            .catch(function(err) {
                console.log('FETCHJSF ERROR HERE')
                console.log(err)
            })
    },
    async fetchDates({ commit }, dateinfo) {
        await apiSetVisualDates({ vdate: dateinfo.vd, isfos: dateinfo.isfos })
            .then(response => {
                if (response) {
                    if (response.data.rush === 'RUSH') {
                        //commit('Sgs/SET_MSG', [true, 'The dates indicate this is a RUSH job, please contact an administrator', 'red', '-1', 'c'], { root: true })
                        commit('SET_RUSH', true)
                    } else {
                        commit('Sgs/RESETMSG', null, { root: true })
                        commit('SET_RUSH', false)
                    }

                    commit('SET_VISUALDATE', response.data.visual)
                    commit('SET_INSTOREDATE', response.data.instore)
                    commit('SET_ARTDUE', response.data.artdue)
                    commit('SET_ARTAPPROVAL', response.data.artapproval)
                    //commit('SET_SHIPDATE', response.data.ship)
                    commit('SET_SHIPDATE', response.data.shiptuesday)
                }
            })
            .catch(function(err) {
                console.log('FETCHDATES ERROR HERE')
                console.log(err)
            })
    },
}

const mutations = {
    RESET(state) {
        const newState = initialState()
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
    RESET_LINEITEMS(state) {
        state.jsf.lineitems = []
    },
    SET_JSFREQUESTFOR(state, val) {
        state.jsfrequestfor = [{ text: 'All', value: 'All' }]

        for (let idx = 0; idx < val[0].length; idx++) {
            state.jsfrequestfor.push({ text: val[0][idx], value: val[0][idx] })
        }
    },
    SET_ZEROPRICELIST(state, val) {
        state.jsfzeropricelist = val
    },
    SET_JSFREQUESTFORSEL(state, val) {
        state.jsfrequestforsel = val
    },
    SET_DOORSCONFIRMED(state, val) {
        state.jsf.doorsconfirmed = val
    },
    SET_RUSH(state, val) {
        state.jsf.rush = val
    },
    SET_PANDE(state, val) {
        state.jsf.havepande = val
    },
    SET_PANDEPM4ONLY(state, val) {
        state.jsf.havepandepm4only = val
    },
    SET_PANDECOUNT(state, val) {
        state.jsf.pandecount = val
    },
    SET_PANDECOUNTPM4ONLY(state, val) {
        state.jsf.pandecountpm4only = val
    },
    SET_PANDESTORES(state, val) {
        state.jsf.pandestores = val
    },
    SET_PANDEITEMS(state, val) {
        state.pandeitems = val
    },
    SET_STATUS(state, val) {
        state.jsf.status = val
    },
    SET_JSF(state, val) {
        //console.log('SETTING JSF')
        //console.log(val)
        if (val.jsf[0].status === 'Rush') {
            val.jsf[0].rush = true
        } else {
            val.jsf[0].rush = false
        }

        state.jsf = val.jsf[0]
    },
    SET_PARTCONFIG(state, val) {
        /* --- initial setup of line items using preconfigured sets --- */
        state.jsf.partconfig = val
        let iidx = 0

        //id: 1000000,
        if (val.tara > 0) {
            state.jsf.lineitems.push({
                idx: iidx,
                id: 999,
                item: 'Tara Set (STANDARD)',
                perdoor: val.tara,
                total: state.jsf.selectedstores.length * val.tara,
                fixed_addition: null,
            })

            iidx++

            if (state.jsf.department != '0') {
                /* -----------------
                 This charlie-foxtrot gets mitigaed (checking department code)
                 when we move to full part config.
                 Taras, vitrines and ledge panels
                 are mucking things up.

                 kitvals check added 2021-11-08,
                 full part config goes active when all programs have an
                 entry in the program_part_xref table
                ------------------*/

                if (!val.kitvals.find(o => o.id === 1000)) {
                    state.jsf.lineitems.push({
                        idx: iidx,
                        id: 1000,
                        item: 'Vitrine 4.5 x 7',
                        perdoor: val.tara,
                        total: state.jsf.selectedstores.length * val.tara,
                        fixed_addition: null,
                    })
                }
            }

            iidx++
        }

        //id: 2000000,
        if (val.ledge > 0) {
            state.jsf.lineitems.push({
                idx: iidx,
                id: 998,
                item: 'Ledge Panel Set - 30x40 (STANDARD)',
                perdoor: val.ledge,
                total: state.jsf.selectedstores.length * val.ledge,
                fixed_addition: null,
            })

            iidx++

            state.jsf.lineitems.push({
                idx: iidx,
                id: 997,
                item: 'Ledge Panel Set - 40x60 (STANDARD)',
                perdoor: val.ledge,
                total: state.jsf.selectedstores.length * val.ledge,
                fixed_addition: null,
            })

            iidx++
        }

        if (val.kitvals) {
            for (let kidx = 0; kidx < val.kitvals.length; kidx++) {
                state.jsf.lineitems.push({
                    idx: iidx,
                    id: val.kitvals[kidx].id,
                    item: val.kitvals[kidx].SpecialtyItem,
                    perdoor: val.kitvals[kidx].setsperdoor,
                    total: state.jsf.selectedstores.length * val.kitvals[kidx].setsperdoor,
                    fixed_addition: null,
                })

                iidx++
            }
        }
    },
    SET_LINEITEMS(state, val) {
        //console.log('SETTING LINE ITEMS')
        //console.log(val)
        state.jsf.lineitems = val
    },
    SET_PROGRAMLIST(state, val) {
        state.programlist = []

        for (let idx = 0; idx < val.length; idx++) {
            state.programlist.push(val[idx])
        }
    },
    SET_CONTACTINFO(state, val) {
        state.jsf.area = val.area
        state.jsf.house = val.house
        state.jsf.brand = val.brand
        state.jsf.buyer = val.buyer
        state.jsf.vendorcontact = val.vendorcontact
        state.jsf.vendoremail = val.vendoremail
        state.jsf.vendorphone = val.vendorphone
        state.jsf.artapprovalname = val.artapprovalname
        state.jsf.artapprovalemail = val.artapprovalemail
    },
    RESET_STORELIST(state) {
        state.storelist = []

        for (let idx = 0; idx < state.basestorelist.length; idx++) {
            if (state.basestorelist[idx].label) {
                state.storelist.push({ value: state.basestorelist[idx].value, label: state.basestorelist[idx].label })
            }
        }
    },
    SET_STORELIST(state, val) {
        state.storelist = []
        state.basestorelist = val

        //console.log('SETTING STORE LIST')
        //console.log(val)

        for (let idx = 0; idx < val.length; idx++) {
            if (val[idx].label) {
                state.storelist.push({ value: val[idx].value, label: val[idx].label })
            }
        }
    },
    SET_SELECTEDSTORESALL(state) {
        state.jsf.selectedstores = []
        for (let idx = 0; idx < state.basestorelist.length; idx++) {
            state.jsf.selectedstores.push(state.basestorelist[idx].value)
        }
    },
    SET_SELECTEDSTORESRANDOM(state, val) {
        state.jsf.selectedstores = []
        for (let idx = 0; idx < val; idx++) {
            state.jsf.selectedstores.push(state.storelist[idx].value)
        }
    },
    SET_SELECTEDSTORESGROUPBYNAME(state, val) {
        state.jsf.selectedstores = []

        //        console.log('BY NAME')
        //        console.log(val)
        //        console.log(state.basestorelist)

        for (let idx = 0; idx < state.basestorelist.length; idx++) {
            if (val.find(o => o.store == state.basestorelist[idx].value)) {
                state.jsf.selectedstores.push(state.basestorelist[idx].value)
            }
        }
    },
    SET_SELECTEDSTORESGROUP(state, val) {
        state.jsf.selectedstores = []
        for (let idx = 0; idx < state.basestorelist.length; idx++) {
            if (state.basestorelist[idx][val] == 1) {
                state.jsf.selectedstores.push(state.basestorelist[idx].value)
            }
        }
    },
    SET_SELECTEDSTORES(state, val) {
        state.jsf.selectedstores = []
        if (val.indexOf('fromform') === -1) {
            for (let idx = 0; idx < val.length; idx++) {
                if (val[idx].selected === 'X') {
                    state.jsf.selectedstores.push(val[idx].value)
                }
            }
        } else {
            for (let idx = 0; idx < val.length; idx++) {
                let usethis = state.basestorelist.find(o => o.value === val[idx])

                if (usethis) {
                    if (usethis.label) {
                        state.jsf.selectedstores.push(usethis.value)
                    }
                }
            }
        }
    },
    SET_JSFLIST(state, jsfrecs) {
        state.jsflist = jsfrecs
    },
    PUSH_JSFLIST(state, rec) {
        state.jsflist.push(rec)
    },
    SET_JSFLISTMASTER(state) {
        state.jsflistmaster = state.jsflist
    },
    SET_JSFLISTSTATES(state) {
        const user = store.getters['Sgs/getUser']

        let props = [
            'all',
            'draft',
            'pending',
            'approved',
            'declined',
            'written',
            'signed',
            'rush',
            'onhold',
            'needsprice',
        ]

        for (let pidx = 0; pidx < props.length; pidx++) {
            state.tabjsfids[props[pidx]] = []
        }

        for (let idx = 0; idx < state.jsflist.length; idx++) {
            //state.jsflist[idx].currentaction = ''

            state.jsflist[idx].created = new Date(state.jsflist[idx].dcreatedts * 1000).toISOString().substring(0, 10)
            state.jsflist[idx].visualdate = new Date(state.jsflist[idx].visualts * 1000).toISOString().substring(0, 10)

            /* --- program status --- */
            state.jsflist[idx].staticon = status.indicator[state.jsflist[idx].status].icon
            state.jsflist[idx].statclass = status.indicator[state.jsflist[idx].status].class
            state.jsflist[idx].section = status.indicator[state.jsflist[idx].status].section

            /* --- empty actions if need be --- */
            if (state.jsflist[idx].actionmenu.length === 1) {
                if (state.jsflist[idx].actionmenu[0].divider) {
                    state.jsflist[idx].actionmenu = []
                }
            }

            /* --- tab values --- */
            if (state.jsflist[idx].holdstatus === '1') {
                state.tabjsfids.onhold.push(state.jsflist[idx].jsfid)
            }

            if (state.jsfzeropricelist.indexOf(parseInt(state.jsflist[idx].jsfid)) !== -1) {
                state.tabjsfids.needsprice.push(state.jsflist[idx].jsfid)
                state.jsflist[idx]['jsfzeropricelist'] = true
            } else {
                state.jsflist[idx]['jsfzeropricelist'] = false
            }

            state.tabjsfids.all.push(state.jsflist[idx].jsfid)
            state.tabjsfids[state.jsflist[idx].statclass].push(state.jsflist[idx].jsfid)

            /* --- contract status --- */
            if (state.jsflist[idx].contractstatus === 'A' || state.jsflist[idx].contractstatus === 'Z') {
                state.jsflist[idx].projecticon = status.indicator['Approved'].icon
            } else {
                state.jsflist[idx].projecticon = status.indicator[state.jsflist[idx].status].icon
            }

            /* --- art status --- */
            state.jsflist[idx].artclass = status.indicator[state.jsflist[idx].artstatus].class
            state.jsflist[idx].articon = status.indicator[state.jsflist[idx].artstatus].icon
            state.jsflist[idx].artsection = status.indicator[state.jsflist[idx].artstatus].section

            if (state.jsflist[idx].isLocked == 0 || state.jsflist[idx].isLocked == 1) {
                /* --- 1 = locked, 0 = was locked, null = never locked --- */
                state.jsflist[idx]['showlock'] = true
                state.jsflist[idx]['tipmsg'] = 'Click to change lock status'

                if (user.isadmin) {
                    state.jsflist[idx]['clicklock'] = 'clickable'
                } else {
                    state.jsflist[idx]['clicklock'] = 'dontclick'
                }

                switch (state.jsflist[idx].isLocked) {
                    case 0:
                    case '0':
                        state.jsflist[idx]['lockbadgecolor'] = 'green'
                        state.jsflist[idx]['lockicon'] = 'mdi-lock-open-outline'

                        if (!user.isadmin) {
                            state.jsflist[idx]['tipmsg'] =
                                'JSF ' + state.jsflist[idx].jsfnumber + ' is available for editing.'
                        }

                        break
                    case 1:
                    case '1':
                        state.jsflist[idx]['lockbadgecolor'] = 'red'
                        state.jsflist[idx]['lockicon'] = 'mdi-lock-outline'

                        if (!user.isadmin) {
                            state.jsflist[idx]['tipmsg'] =
                                'JSF ' +
                                state.jsflist[idx].jsfnumber +
                                ' is locked, please contact SGS if you need to edit.'
                        }

                        break
                }
            } else {
                state.jsflist[idx]['showlock'] = false
            }
        }
    },
    SET_SEARCH(state, val) {
        state.search = val
    },
    SET_MUINFO(state, val) {
        state.muinfo = val
    },
    SET_SHOWMUINFO(state, val) {
        state.showmuinfo = val
    },
    SET_ID(state, val) {
        state.jsf.id = val
    },
    SET_JSFID(state, val) {
        state.jsf.jsfid = val
    },
    SET_DEP(state, val) {
        state.jsf.department = val
    },
    SET_MIC(state, val) {
        state.jsf.mic = val
    },
    SET_REQUESTEDFOR(state, val) {
        state.jsf.requestedfor = val
    },
    SET_REQUESTTYPE(state, val) {
        state.jsf.requesttype = val
    },
    SET_PROGRAM(state, val) {
        state.jsf.program = val
    },
    SET_TITLE(state, val) {
        state.jsf.title = val
    },
    SET_VISUALDATE(state, val) {
        state.jsf.visualdate = val
    },
    SET_INSTOREDATE(state, val) {
        state.jsf.instoredate = val
    },
    SET_ARTDUE(state, val) {
        state.jsf.artdue = val
    },
    SET_ARTAPPROVAL(state, val) {
        state.jsf.artapproval = val
    },
    SET_SHIPDATE(state, val) {
        state.jsf.shipdate = val
    },
    SET_REQUESTEDBY(state, val) {
        state.jsf.requestedby = val
    },
    SET_AREA(state, val) {
        state.jsf.area = val
    },
    SET_HOUSE(state, val) {
        state.jsf.house = val
    },
    SET_BRAND(state, val) {
        state.jsf.brand = val
    },
    SET_BUYER(state, val) {
        state.jsf.buyer = val
    },
    SET_VENDORCONTACT(state, val) {
        state.jsf.vendorcontact = val
    },
    SET_VENDOREMAIL(state, val) {
        state.jsf.vendoremail = val
    },
    SET_VENDORPHONE(state, val) {
        state.jsf.vendorphone = val
    },
    SET_ARTAPPROVALNAME(state, val) {
        state.jsf.artapprovalname = val
    },
    SET_ARTAPPROVALEMAIL(state, val) {
        state.jsf.artapprovalemail = val
    },
    SET_NOTES(state, val) {
        state.jsf.notes = val
    },
    SET_CREATED(state, val) {
        state.jsf.created = val
    },
    SET_LASTUPDATE(state, val) {
        state.jsf.lastupdate = val
    },
    SET_FIXEDKIT(state, val) {
        state.jsf.fixedkit = val
    },
    SET_TABJSFIDS(state, val) {
        state.tabjsfids = val
    },
    SET_DUMMY() {},
}

const getters = {
    getSearch(state) {
        return state.search
    },
    getJsfList(state) {
        return state.jsflist
    },
    getJsfListMaster(state) {
        return state.jsflistmaster
    },
    getJsfRequestFor(state) {
        return state.jsfrequestfor
    },
    getJsfRequestForSel(state) {
        return state.jsfrequestforsel
    },
    getDoorsConfirmed(state) {
        return state.jsf.doorsconfirmed
    },
    getRush(state) {
        return state.jsf.rush
    },
    getPande(state) {
        return state.jsf.havepande
    },
    getPandePM4Only(state) {
        return state.jsf.havepandepm4only
    },
    getPandecount(state) {
        return state.jsf.pandecount
    },
    getPandecountPM4Only(state) {
        return state.jsf.pandecountpm4only
    },
    getPandestores(state) {
        return state.jsf.pandestores
    },
    getPandeitems(state) {
        return state.pandeitems
    },
    getStatus(state) {
        return state.jsf.status
    },
    getLineItems(state) {
        return state.jsf.lineitems
    },
    getPartConfig(state) {
        return state.jsf.partconfig
    },
    getStoreList(state) {
        return state.storelist
    },
    getSelectedStores(state) {
        return state.jsf.selectedstores
    },
    getId(state) {
        return state.jsf.id
    },
    getJsfId(state) {
        return state.jsf.jsfid
    },
    getDep(state) {
        return state.jsf.department
    },
    getMic(state) {
        return state.jsf.mic
    },
    getRequestedFor(state) {
        return state.jsf.requestedfor
    },
    getRequestedBy(state) {
        let user = store.getters['Sgs/getUser']

        if (state.jsf.jsfid === '[NEW]') {
            state.jsf.requestedby = user.username
            return user.username
        } else {
            return state.jsf.requestedby
        }
    },
    getRequestType(state) {
        return state.jsf.requesttype
    },
    getProgram(state) {
        return state.jsf.program
    },
    getCreated(state) {
        return state.jsf.created
    },
    getLastUpdate(state) {
        return state.jsf.lastupdate
    },
    getTitle(state) {
        return state.jsf.title
    },
    getVisualDate(state) {
        return state.jsf.visualdate
    },
    getInStoreDate(state) {
        return state.jsf.instoredate
    },
    getArtDue(state) {
        return state.jsf.artdue
    },
    getArtApproval(state) {
        return state.jsf.artapproval
    },
    getShipDate(state) {
        return state.jsf.shipdate
    },
    getArea(state) {
        return state.jsf.area
    },
    getHouse(state) {
        return state.jsf.house
    },
    getBrand(state) {
        return state.jsf.brand
    },
    getBuyer(state) {
        return state.jsf.buyer
    },
    getVendorContact(state) {
        return state.jsf.vendorcontact
    },
    getVendorEmail(state) {
        return state.jsf.vendoremail
    },
    getVendorPhone(state) {
        return state.jsf.vendorphone
    },
    getArtApprovalName(state) {
        return state.jsf.artapprovalname
    },
    getArtApprovalEmail(state) {
        return state.jsf.artapprovalemail
    },
    getNotes(state) {
        return state.jsf.notes
    },
    getIsLocked(state) {
        return state.jsf.isLocked
    },
    getBaseStoreList(state) {
        return state.basestorelist
    },
    getFixedKit(state) {
        return state.jsf.fixedkit
    },
    getTabJsfIds(state) {
        return state.tabjsfids
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
